import {
  ActionButton,
  ActionsGroup,
  Col,
  DisplayData
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const ServicosListRow = ({ servico, index, editServico, excludeServico }) => {
  return (
    <div key={index}>
      <div className="display-data border small mt-xs has-btn-actions">
        <div className="row" tabIndex="0" autoFocus>
          <Col sm={4} md={6}>
            <DisplayData title="Descrição">
              {servico.servico.descricao}
            </DisplayData>
          </Col>
          <Col sm={2} md={3}>
            <DisplayData title="Desconto">
              {(servico.percentualDesconto || '') + '%'}
            </DisplayData>
          </Col>
          <Col sm={2} md={4}>
            <DisplayData title="Alíquota">{servico.aliquota || ''}</DisplayData>
          </Col>
          <ActionsGroup>
            <React.Fragment>
              <ActionButton
                data-test-id={`buttonEdit-${index}`}
                key="edit-button"
                icon="pencil-alt"
                label="Editar"
                onClick={() => editServico(index)}
              />
              <ActionButton
                data-test-id={`buttonDelete-${index}`}
                key="delete-button"
                icon="trash-alt"
                label="Excluir"
                onClick={() => excludeServico(index)}
              />
            </React.Fragment>
          </ActionsGroup>
        </div>
      </div>
    </div>
  );
};

ServicosListRow.propTypes = {
  servico: PropTypes.object.isRequired,
  editServico: PropTypes.func.isRequired,
  excludeServico: PropTypes.func.isRequired
};
export default ServicosListRow;
