import { AutoComplete, Col, ErrorText, Row } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const errorMessages = {
  servico: 'Selecione um serviço',
  percentualDescontoOrAlquita:
    'Desconto base calculo ou alíquota não foi informado',
  percentualDescontoMaxValue: 'Desconto acima de 100%',
  aliquotaMinValue: 'Alíquota negativa',
  descontoBaseCalculo: `Percentual de desconto 
    deve ser de 100% para o tipo de 
    incentivo Isenção ou Imunidade.`
};

const ServicoNewEditForm = ({
  servico,
  error,
  loadServicos,
  onSelect,
  onSaveServico,
  onChangeBaseCalculoServico,
  onChangeAliquotaServico,
  onCancelServico
}) => {
  return (
    <Fragment>
      <Row>
        <Col md={5}>
          <label htmlFor="servico">Serviço</label>
          <AutoComplete
            data-test-id="autocomplete-servico"
            name="servico"
            className={error.servico ? 'error' : ''}
            placeholder="Pesquise pelo código ou descrição"
            value={servico.servico}
            onSearch={loadServicos}
            onSelect={onSelect}
          />
          {error.servico && <ErrorText>{errorMessages.servico}</ErrorText>}
        </Col>
        <div data-test-id="descontoBaseCalculo">
          <Col md={2}>
            <label htmlFor="percentualDesconto">Desc. Base Calculo(%)</label>
            <input
              className={error.percentualDesconto ? 'error' : ''}
              name="percentualDesconto"
              type="number"
              value={servico.percentualDesconto || ''}
              onChange={onChangeBaseCalculoServico}
            />
            {error.percentualDescontoMaxValue && (
              <ErrorText>{errorMessages.percentualDescontoMaxValue}</ErrorText>
            )}
          </Col>
        </div>
        <div data-test-id="descontoAliquota">
          <Col md={2}>
            <label htmlFor="aliquota">Alíquota</label>
            <input
              className={error.aliquota ? 'error' : ''}
              name="aliquota"
              type="number"
              value={servico.aliquota || ''}
              onChange={onChangeAliquotaServico}
            />
            {error.aliquotaMinValue && (
              <ErrorText>{errorMessages.aliquotaMinValue}</ErrorText>
            )}
          </Col>
        </div>
      </Row>
      <Row>
        <Col>
          <div style={{ marginLeft: '15px' }}>
            {(error.percentualDesconto || error.aliquota) && (
              <ErrorText>{errorMessages.percentualDescontoOrAlquita}</ErrorText>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <button
            data-test-id="btnSalvarServico"
            className="btn positive inline mt-xs"
            type="button"
            onClick={onSaveServico}
          >
            Salvar
          </button>
          <button
            data-test-id="btnCancelarServico"
            className="btn negative inline mt-xs"
            type="button"
            onClick={onCancelServico}
          >
            Cancelar
          </button>
        </Col>
      </Row>
    </Fragment>
  );
};

ServicoNewEditForm.propTypes = {
  servico: PropTypes.object.isRequired,
  error: PropTypes.object,
  loadServicos: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSaveServico: PropTypes.func.isRequired,
  onCancelServico: PropTypes.func.isRequired,
  onChangeBaseCalculoServico: PropTypes.func.isRequired,
  onChangeAliquotaServico: PropTypes.func.isRequired
};

export default ServicoNewEditForm;
