import { SectionTitle } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import ServicoNewEditForm from './ServicoNewEditForm';
import ServicosListRow from './ServicosListRow';

const ServicosForm = ({
  incentivoFiscal,
  servico,
  errorServico,
  showFormServico,
  onChangeBaseCalculoServico,
  onChangeAliquotaServico,
  onSelect,
  newServico,
  loadServicos,
  onChangeServico,
  onSaveServico,
  onCancelServico,
  onExcluirServico,
  onEditServico
}) => {
  return (
    <Fragment>
      <SectionTitle hasButton={true}>
        Serviços Adicionados
        {incentivoFiscal.descServicosEspecificos === true ? (
          <button
            data-test-id="buttonNovo"
            className="btn module-color"
            onClick={newServico}
          >
            + Novo
          </button>
        ) : (
          ''
        )}
      </SectionTitle>
      {showFormServico ? (
        <ServicoNewEditForm
          servico={servico}
          error={errorServico}
          loadServicos={loadServicos}
          onSelect={onSelect}
          onChangeBaseCalculoServico={onChangeBaseCalculoServico}
          onChangeAliquotaServico={onChangeAliquotaServico}
          onSaveServico={onSaveServico}
          onChangeServico={onChangeServico}
          onCancelServico={onCancelServico}
        />
      ) : (
        incentivoFiscal.servicos &&
        incentivoFiscal.servicos.map((servico, index) => {
          return (
            <ServicosListRow
              key={index}
              index={index}
              servico={servico}
              editServico={onEditServico}
              excludeServico={onExcluirServico}
            />
          );
        })
      )}
    </Fragment>
  );
};

ServicosForm.propTypes = {
  incentivoFiscal: PropTypes.object.isRequired,
  servico: PropTypes.object,
  errorServico: PropTypes.object,
  showFormServico: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  newServico: PropTypes.func.isRequired,
  loadServicos: PropTypes.func.isRequired,
  onChangeServico: PropTypes.func.isRequired,
  onChangeBaseCalculoServico: PropTypes.func.isRequired,
  onChangeAliquotaServico: PropTypes.func.isRequired,
  onSaveServico: PropTypes.func.isRequired,
  onCancelServico: PropTypes.func.isRequired,
  onExcluirServico: PropTypes.func.isRequired,
  onEditServico: PropTypes.func.isRequired
};

ServicosForm.defaultProps = {
  percentualDesconto: 100
};

export default ServicosForm;
